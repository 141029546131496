import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import '../styles/global.css';
import theme from '../styles/theme';
import { SEO } from './SEO';

const Layout = ({ children, pageContext }) => {
  const { t } = useTranslation('footer');

  return (
    <div style={{height: '100%'}}>
      <SEO />
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </div>
  );
};

export default Layout;

export const Head = () => (
  <SEO />
);
