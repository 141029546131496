// import React from 'react';
// import PropTypes from 'prop-types';
// import { Helmet } from 'react-helmet';
// import { useLocation } from '@reach/router';
// import { useStaticQuery, graphql } from 'gatsby';
// import { useTranslation } from 'react-i18next';
//
// import { usePageContext } from '../i18n/page-context';
//
// const SEO = ({ title, description, image, article, titlePrefix }) => {
//   const { pathname } = useLocation();
//   const { site } = useStaticQuery(query);
//   const { t } = useTranslation();
//   const { lang } = usePageContext();
//
//   const {
//     defaultTitle,
//     defaultDescription,
//     siteUrl,
//     defaultImage,
//   } = site.siteMetadata;
//
//   const seo = {
//     title: titlePrefix ? (titlePrefix + defaultTitle) : (title || defaultTitle),
//     description: description || t(defaultDescription),
//     image: `${siteUrl}${image || defaultImage}`,
//     url: `${siteUrl}${pathname}`,
//   };
//
//   return (
//     <Helmet title={seo.title}>
//       <html lang={lang} />
//
//       <meta name="description" content={seo.description} />
//       <meta name="image" content={seo.image} />
//
//       {seo.url && <meta property="og:url" content={seo.url} />}
//
//       {(article ? true : null) && <meta property="og:type" content="article" />}
//
//       {seo.title && <meta property="og:title" content={seo.title} />}
//
//       {seo.description && (
//         <meta property="og:description" content={seo.description} />
//       )}
//
//       {seo.image && <meta property="og:image" content={seo.image} />}
//
//       <meta
//         property="og:locale"
//         content={lang}
//       />
//     </Helmet>
//   );
// };
//
// export default SEO;
//
// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   article: PropTypes.bool,
// };
//
// SEO.defaultProps = {
//   title: null,
//   description: null,
//   image: null,
//   article: false,
// };
//
// const query = graphql`
//   query SEO {
//     site {
//       siteMetadata {
//         defaultTitle: title
//         defaultDescription: description
//         siteUrl: siteUrl
//         defaultImage: image
//       }
//     }
//   }
// `;

import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '../hooks/use-site-metadata';
import { usePageContext } from '../i18n/page-context';

export const SEO = ({ title, description, pathname, children, titlePrefix, isArticle }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
  } = useSiteMetadata();

  const { lang } = usePageContext();

  const seo = {
    title: titlePrefix ? (titlePrefix + ' | ' + defaultTitle) : (title || defaultTitle),
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ''}`,
  };

  return (
    <Helmet>
      <html lang={lang} />

      <title>{seo.title}</title>

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <link id="icon" rel="icon" href={seo.image} type="image/x-icon"/>

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:locale" content={lang} />
      {isArticle && <meta property="og:type" content="article" />}

      {children}
    </Helmet>
  );
};
